import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { CallDashboardComponent } from './call-dashboard/call-dashboard.component';
import { SharedComponentsModule } from '@modules/shared-components/shared-components.module';
import { PersonProfilesModule } from '@modules/person-profiles/person-profiles.module';

@NgModule({
  declarations: [
    MainDashboardComponent,
    CallDashboardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    SharedComponentsModule,
    PersonProfilesModule,
  ],
  exports: [
    MainDashboardComponent,
    CallDashboardComponent
  ]
})
export class DashboardsModule { }