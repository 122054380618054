<div class="container-frame" *ngIf="person$ | async as person"
     fxFlexFill fxLayout="row" fxLayoutGap="10px">
  <div fxLayout="column" class="filter-column">
    <app-search-bar></app-search-bar>
    <app-person-filter-list #filterList fxFlex [person]="person$"></app-person-filter-list>
  </div>
  <div fxFlex="33" fxLayout="column" class="contact-info-column">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
      <button mat-flat-button color="primary" routerLink="/activists/new">
        <span>New Activist or Donor</span>
      </button>
      <div fxFlex></div>
      <button [color]="person.verified===1?'primary':'warn'" mat-flat-button
              [disabled]="!person.person_id || !authSvc.getCurrentUserName()"
              [title]="getVerificationTitle(person)"
              (click)="toggleVerificationStatus(person.person_id, person)">
        <mat-icon style="font-size: 20px;">{{person.verified===1?"verified_user":"remove_moderator"}}</mat-icon>
        <span>{{person.verified===1?"VERIFIED":"UNVERIFIED"}}</span>
      </button>
      <button mat-flat-button [color]="person.mute_email?'warn':'primary'" (click)="toggleEmailMute(person)"
              [disabled]="!person.person_id || !authSvc.getCurrentUserName()"
              [title]="person.person_id?'Click here to ' + (person.mute_email?'include ':'temporarily remove ') + peopleSvc.getPersonName(person) + (person.mute_email?' in':' from') + ' all future emails.':''">
        <mat-icon style="font-size: 20px;">{{person.mute_email?'block':'email'}}</mat-icon>
        <span>{{person.mute_email?'Email Muted':'Email Active'}}</span>
      </button>
      <button *ngIf="displayMode === 'view'" mat-icon-button
              class="pointer" [matMenuTriggerFor]="personMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #personMenu="matMenu">
        <button mat-menu-item (click)="displayMode = 'edit'">
          <mat-icon>edit</mat-icon><span>Edit</span>
        </button>
        <button mat-menu-item (click)="deletePerson(person)">
          <mat-icon>delete</mat-icon><span>Delete</span>
        </button>
      </mat-menu>
    </div>
    <div fxFlex *ngIf="personLoading" fxLayout="row" fxLayoutAlign="center start"
         class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
    <ng-container *ngIf="!personLoading">
      <div fxLayout="row" fxLayoutAlign="center center" class="image-row">
        <img *ngIf="person.img_url" [src]="person.img_url" >
        <mat-icon *ngIf="!person.img_url">person</mat-icon>
      </div>
      <app-person-view fxFlex *ngIf="displayMode === 'view'" [person]="person$"
                       (person-updated)="person$.next($event)"></app-person-view>
      <app-person-form fxFlex class="overflow-auto" *ngIf="displayMode === 'edit'"
                       [person]="person$" (cancel)="displayMode = 'view'"
                       (person-created)="filterList.refreshList()"
                       (person-updated)="person$.next($event); filterList.refreshList(); displayMode = 'view';">
      
      </app-person-form>
    </ng-container>
  </div>
  <div fxFlex fxLayout="column" fxLayoutGap="5px">
    <div fxLayout="row" class="selector-row">
      <div fxFlex class="pane" *ngFor="let pane of activePanes" (click)="activePane = pane;"
           [ngClass]="{'pane-selected': activePane === pane, 'hide': pane === 'Donations' && !authSvc.checkPermissions(['view_donations'])}">
        <span>{{pane}}</span>
      </div>
    </div>
    <div fxFlex style="overflow: auto;">
      <app-activist-notes-table fxFlexFill [fxShow]="activePane === 'General'"
                                [person]="person$"></app-activist-notes-table>
      <app-action-table fxFlexFill [fxShow]="activePane === 'Activism'"
                        [person]="person$"></app-action-table>
      <app-email-results-table fxFlexFill [fxShow]="activePane === 'Email Messages'"
                               [person]="person$"></app-email-results-table>
      <app-donations-table fxFlexFill [fxShow]="activePane === 'Donations'"
                           [person]="person$"></app-donations-table>
      <app-contact-log-table [fxShow]="activePane === 'Contact Log'"
                             [person]="person$"></app-contact-log-table>
      <div id="call-table"
           [fxShow]="activePane === 'Calls'">
        <table>
          <thead>
            <tr>
              <th><span>Date</span></th>
              <th><span>Disposition Code</span></th>
              <th><span>Disposition Description</span></th>
              <th><span>Call Time</span></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let call of person.calls">
              <td><span>{{call.date | date: 'shortDate'}}</span></td>
              <td style="text-align: center"><span>{{call.disposition_code}}</span></td>
              <td style="text-align: center"><span>{{call.disposition_desc}}</span></td>
              <td><span>{{call.connect_time}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>