<div id="container" [formGroup]="emailForm">
  <div class="envelope-row">
    <mat-form-field>
      <mat-label>From:</mat-label>
      <mat-select formControlName="sender" name="sender">
        <mat-option *ngFor="let opt of fromOptions"
                    [value]="opt.name + ' <' + opt.email + '>'">
          <span>'{{opt.name}}' &#60;{{opt.email}}&#62;</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Reply To:</mat-label>
      <mat-select formControlName="replyTo" name="replyTo">
        <mat-option *ngFor="let opt of replyToOptions"
                    [value]="opt.name + ' <' + opt.email + '>'">
          <span>'{{opt.name}}' &#60;{{opt.email}}&#62;</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-icon-button color="primary"
            [matMenuTriggerFor]="templateMenu">
      <mat-icon>photo_library</mat-icon>
    </button>
    <mat-menu #templateMenu="matMenu">
      <button mat-menu-item *ngFor="let template of emailTemplates"
              (click)="activeTemplate$.next(template)">
        <span>{{template.name}}</span>
      </button>
    </mat-menu>
  </div>
  <div class="envelope-row">
    <mat-form-field style="width: 100%;">
      <mat-label>To:</mat-label>
      <mat-chip-grid #recipientControl>
        <mat-chip-row *ngFor="let recipient of recipients; let index = index;"
                      (removed)="removeRecipient(index)">
          <div class="inner-chip">
            <mat-icon *ngIf="recipient.type === 'list'" style="margin-right: 12px;">groups</mat-icon>
            <span>{{displayResult(recipient)}}</span>
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </div>
        </mat-chip-row>
      </mat-chip-grid>
      <input class="w-100" matInput autocomplete="off"
             #recipientInput name="to" type="text"
             [matChipInputFor]="recipientControl"
             [matAutocomplete]="search"
             [ngModel]="recipientInput$ | async"
             (ngModelChange)="recipientInput$.next($event)"
             [ngModelOptions]="{standalone: true}"
             (keyup)="recipientKeyup($event)">
      <button matSuffix mat-icon-button 
              (click)="recipientInput$.next(''); recInput.nativeElement.value = '';">
        <mat-icon style="font-size: 16px;">close</mat-icon>
      </button>
      <mat-autocomplete autoActiveFirstOption #search="matAutocomplete"
                        (optionSelected)="addRecipientFromInput($event)">
        <mat-option *ngFor="let result of searchResults$ | async" [value]="result">
          <mat-icon>{{result.type === "person"?"person":"groups"}}</mat-icon>
          <span>{{displayResult(result)}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button mat-icon-button color="primary" [matMenuTriggerFor]="contentTemplateMenu">
      <mat-icon>file_open</mat-icon>
    </button>
    <mat-menu #contentTemplateMenu="matMenu">
      <button mat-menu-item (click)="emailForm.get('html_content').reset()">
        <span>Clear Email Content</span>
      </button>
      <button mat-menu-item (click)="emailForm.patchValue({html_content: contentTemplates.notes})">
        <span>Weekly Call Notes</span>
      </button>
    </mat-menu>
  </div>
  <div class="envelope-row">
    <mat-form-field style="width: 100%">
      <mat-label>Subject:</mat-label>
      <input matInput type="text" formControlName="subject"
             name="subject" autocomplete="off">
    </mat-form-field>
  </div>
  <div id="editor-frame">
    <div id="email-header"
         [innerHtml]="email.topSet + email.header + email.bodyTop | safeHtml"></div>
    <angular-editor #editor formControlName="html_content"
                    class="d-flex flex-column flex-grow-1 flex-shrink-1"
                    [placeholder]="'Enter email text here...'"
                    [config]="editorConfig"></angular-editor>
    <div class="email-footer"
         [innerHtml]="email.bodyBottom + email.footer + email.bottomSet | safeHtml"></div>
  </div>
  <div class="action-buttons">
    <button mat-flat-button color="primary" (click)="sendSESEmail(emailForm.value)"
            [title]="checkPermission('send_emails')?'Send Email to Recipients':'You are not permitted to send emails through this application'">
      <span>Send</span>
    </button>
    <button mat-flat-button color="primary" (click)="openPreviewDialog()"
            [disabled]="!emailForm.get('html_content').value">
      <span>Preview</span>
    </button>
    <button mat-flat-button color="primary" [disabled]="!checkPermission('draft_emails') || !emailForm.get('html_content').value"
            [title]="checkPermission('draft_emails')?'Save This Email to Drafts':'You are not permitted to save emails through this application'">
      <span>Save</span>
    </button>
    <button mat-flat-button *ngIf="false" color="primary" [disabled]="!checkPermission('send_emails') || !emailForm.get('html_content').value"
            [title]="checkPermission('send_emails')?'Schedule Email Send to Recipients':'You are not permitted to send emails through this application'">
      <span>Schedule Send</span>
    </button>
  </div>
</div>