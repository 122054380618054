import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { filter, switchMap, distinctUntilChanged } from 'rxjs/operators';

import { DashboardService } from '@modules/dashboards/dashboard.service';
import { PeopleService } from '@modules/person-profiles/people.service';
import { RouterService } from '@modules/shared-components/router.service';

@Component({
  selector: 'app-call-dashboard',
  templateUrl: './call-dashboard.component.html',
  styleUrls: ['./call-dashboard.component.scss']
})
export class CallDashboardComponent implements OnInit {
  calls: any[] = [];
  codes: number[] = [];
  people: any[] = [];
  views: string[] = ["Totals", "People"];
  viewMode: string = this.views[0];
  filter$: BehaviorSubject<any> = new BehaviorSubject<any>({
    pageSize: 35,
    pageIndex: 0,
    code: 9
  });
  totalPeople: number = 0;
  person$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  
  constructor(private dashboardSvc: DashboardService,
              private peopleSvc: PeopleService,
              protected routerSvc: RouterService,
              private route: ActivatedRoute) {}
  
  ngOnInit() {
    this.filter$.pipe(
      switchMap((filter: any) => this.dashboardSvc.getPeople(filter))
    ).subscribe((response: any) => {
      this.people = response.people;
      this.totalPeople = response.count;
    });
    this.dashboardSvc.getCalls().subscribe((response: any[]) => this.calls = response);
    this.dashboardSvc.getCodes().subscribe((response: any[]) => this.codes = response);
    this.route.queryParamMap.pipe(
      filter((params: ParamMap) => !!params.get('personId')),
      distinctUntilChanged((prev: ParamMap, curr: ParamMap) => prev.get('personId') === curr.get('personId')),
      switchMap((params: ParamMap) => this.peopleSvc.getPerson(+params.get('personId')))
    ).subscribe((response: any) => this.person$.next(response));
  }
  
  findCode = (call: any, code: number) => {
    return (call.responses.find((resp: any) => resp.disposition_code === code) || {}).count || 0;
  }
  changePageSize = (pageSize: number, filter: any, filter$: BehaviorSubject<any>) => {
    filter.pageSize = pageSize;
    filter$.next(filter);
  }
  incrementPage = (increment: number, filter: any, filter$: BehaviorSubject<any>) => {
    filter.pageIndex = filter.pageIndex + increment;
    filter$.next(filter);
  }
}