import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  url: string = "https://slingofdavid.com:3000/"
  
  constructor(private http: HttpClient) { }
  
  getDashboardData = (filter: any) => {
    return this.http.get(this.url + "dashboards/data", {params: filter});
  }
  getActivistMetricReport = (filter: any) => {
    return this.http.get(this.url + "dashboards/report", {params: filter, responseType: 'blob'});
  }
  getCalls = () => this.http.get(this.url + "calls");
  getCodes = () => this.http.get(this.url + "calls/codes");
  
  getPeople = (filter: any) => this.http.get(this.url + "calls/people", {params: filter});
}