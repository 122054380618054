<ng-container *ngIf="filter$ | async as filter">
  <div id="selector-row">
    <div *ngFor="let view of views" (click)="viewMode = view"
         [ngClass]="{'selected': view === viewMode}">
      <span>{{view}}</span>
    </div>
  </div>
  <table id="total-table" *ngIf="viewMode === 'Totals'">
    <thead>
      <tr>
        <th><span>Date</span></th>
        <th><span>Count</span></th>
        <th *ngFor="let code of codes"><span>{{code}}</span></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let call of calls">
        <td><span>{{call.date | date: 'shortDate'}}</span></td>
        <td><span>{{call.count | number: '1.0'}}</span></td>
        <td *ngFor="let code of codes"><span>{{findCode(call, code) | number: '1.0'}}</span></td>
      </tr>
    </tbody>
  </table>
  <div id="people-container" *ngIf="viewMode === 'People'">
    <div>
      <app-pagination item-name="People" [total-records]="totalPeople"
                      [page-index]="filter.pageIndex" [page-size]="filter.pageSize"
                      (change-page-size)="changePageSize($event, filter, filter$)"
                      (page-increment)="incrementPage($event, filter, filter$)">
      </app-pagination>
      <table id="person-table">
        <thead>
          <tr>
            <th><span>Person</span></th>
            <th><span>Disposition Code</span></th>
            <th><span>Count</span></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let person of people">
            <td (click)="routerSvc.setQueryParams({personId: person.person_id})">
              <span>{{person.name}}</span>
            </td>
            <td><span>{{person.disposition_code}}</span></td>
            <td><span>{{person.count}}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <app-person [person]="person$"></app-person>
  </div>
</ng-container>